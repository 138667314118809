import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="bound region-padding-large">
      <p className="h2">Page not found</p>
      <p>The page you are looking for is unavailable.</p>
      <p>Return to <Link to="/">UBC&rsquo;s Virtual Graduation homepage</Link>.</p>
    </div>
  </Layout>
)

export default NotFoundPage
